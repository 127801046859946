// Override default variables before the import
$body-bg: transparent;
$theme-colors: (
  'primary': #ffb300,
  'danger': #ff4136
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.wrap {
  padding-left: 0;
  padding-right: 0;
}

.widget-header {
  font-size: 32px;
  font-weight: bold;
}

.map-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 1001;
  transition: all 200ms ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.overlay:hover {
  opacity: 1;
}
